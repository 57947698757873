<template>
    <div class="detail-container">
        <div class="detail-container-left">
            <div class="paper-title">
                {{ paperInfo.theory_test_paper_name }}
            </div>
            <template v-if="Object.keys(paperInfo.theory_topic_data).length !== 0">
                <ExamTheoryPaper :paperDetail.sync="paperInfo.theory_topic_data"/>
            </template>
        </div>
        <div class="detail-container-right">
            <div class="top-shelter">
                <div class="top-shelter-color"></div>
            </div>
            <div class="right-header">
                <div class="left">答题卡</div>
                <div class="packUp-btn" :class="hidePackUp === false ? 'current' : ''" @click="packUpBtn">
                    {{packUpText}}
                    <i class="iconfont">&#xe65e;</i></div>
            </div>
            <el-scrollbar class="right-wrapper-box" :native="false">
                <div class="packUp-wrapper" v-show="hidePackUp">
                    <div class="right-content">
                        <div class="right-item" v-for="(itemOne, itemOneKey, itemOneIndex) in answerSheet"
                             :key="`answerSheet_${itemOneIndex}`">
                            <div class="item-one">{{ itemOneKey | formatHeadLineType }}</div>
                            <div class="item-two">
                                <div class="item" :class="itemTwo.selected ? 'done' : ''"
                                     v-for="(itemTwo, itemTwoIndex) in itemOne" :key="`itemOne_${itemTwoIndex}`">
                                    {{ itemTwo.number }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="status-wrapper">
                        <div class="status-item done">
                            <div class="color"></div>
                            <div class="text">已做</div>
                        </div>
                        <div class="status-item">
                            <div class="color"></div>
                            <div class="text">未作答</div>
                        </div>
                    </div>
                </div>
                <div class="submit-btn-bj">
                    <div class="submit-btn" @click="submitPaper">交卷</div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import {jding_info, jding_save} from '@/utils/apis'
    import ExamTheoryPaper from '@/components/evaluation/ExamTheoryPaper.vue'

    export default {
        name: "Index",
        components: {
            ExamTheoryPaper
        },
        data() {
            return {
                paperInfo: {
                    theory_test_paper_name: this.$route.query.exam_name,
                    theory_topic_data: {}
                },
                packUpText: '收起',
                hidePackUp: true,
            }
        },
        filters: {
            // 题型
            formatHeadLineType(type) {
                const types = {
                    'single_data': '单选题',
                    'multiple_data': '多选题',
                    'judge_data': '判断题',
                    'fillblank_data': '填空题',
                    'answer_data': '问答题',
                }
                return types[type]
            },
        },
        computed: {
            // 答题卡显示已答
            answerSheet() {
                let allTmp = {}
                let sheetTmp = {}
                for (let key in this.paperInfo.theory_topic_data) {
                    for (let i = 0; i < this.paperInfo.theory_topic_data[key].length; i++) {
                        let itemTmp = this.paperInfo.theory_topic_data[key][i];
                        sheetTmp = {
                            selected: false,
                            number: i + 1,
                        }
                        if (!allTmp.hasOwnProperty(key)) {
                            allTmp[key] = []
                        }
                        if (key === 'single_data') {
                            if (itemTmp.stu_answer.length !== 0 && itemTmp.stu_answer[0] !== '') {
                                sheetTmp.selected = true
                            }
                            allTmp.single_data.push(sheetTmp)
                        }
                        if (key === 'judge_data') {
                            if (itemTmp.stu_answer.length !== 0 && itemTmp.stu_answer[0] !== '') {
                                sheetTmp.selected = true
                            }
                            allTmp.judge_data.push(sheetTmp)
                        }
                        if (key === 'multiple_data') {
                            if (itemTmp.stu_answer.length !== 0 && itemTmp.stu_answer.length !== 0) {
                                sheetTmp.selected = true
                            }
                            allTmp.multiple_data.push(sheetTmp)
                        }
                        if (key === 'fillblank_data') {
                            let stuValue = itemTmp.stu_answer;
                            stuValue.map(item => {
                                if (item.name) {
                                    sheetTmp.selected = true
                                }
                            })
                            allTmp.fillblank_data.push(sheetTmp)
                        }
                        if (key === 'answer_data') {
                            if (itemTmp.stu_answer.length !== 0 && itemTmp.stu_answer[0] !== '') {
                                sheetTmp.selected = true
                            }
                            allTmp.answer_data.push(sheetTmp)
                        }
                    }
                }
                return allTmp
            }
        },
        mounted() {
            this.getTheoryexamStartTheoryExam()
        },
        methods: {
            // 获取考试试卷信息
            getTheoryexamStartTheoryExam() {
                let params = {
                    id: this.$route.query.id
                }
                jding_info(params).then((res) => {
                    for (const key in res.data.data) {
                        res.data.data[key].forEach(item => {
                            if (!item.hasOwnProperty('stu_answer')) {
                                this.$set(item, 'stu_answer', [])
                                if (key === 'single_data' || key === 'judge_data' || key === 'answer_data') {
                                    item.stu_answer = ['']
                                }
                                if (key === 'multiple_data') {
                                    item.stu_answer = []
                                }
                                if (key === 'fillblank_data') {
                                    for (let i = 0; i < item.options.length; i++) {
                                        let tmp = {
                                            name: ''
                                        }
                                        item.stu_answer.push(tmp)
                                    }
                                }
                            } else {
                                if (key === 'fillblank_data') {
                                    let list = [];
                                    for (let i = 0; i < item.stu_answer.length; i++) {
                                        let tmp = {
                                            name: item.stu_answer[i]
                                        }
                                        list.push(tmp)
                                    }
                                    item.stu_answer = list
                                }
                                if (typeof (item.stu_answer) == 'string') {
                                    item.stu_answer = JSON.parse(item.stu_answer)
                                }
                            }
                        })
                        if(res.data.data[key].length == 0){
                            delete res.data.data[key]
                        }
                        //删除填空和问答
                        if (key === 'fillblank_data') {
                            delete res.data.data[key]
                        }
                        if (key === 'answer_data') {
                            delete res.data.data[key]
                        }
                    }
                    this.paperInfo.theory_topic_data = res.data.data;
                }).catch((err) => {
                    this.$router.push({path: '/student/theory/waiting', query: {id: this.$route.query.id}})
                    console.log('err', err)
                })
            },
            // 是否交卷
            submitPaper() {
                let count = 0;
                for (let i in this.answerSheet) {
                    for (let j = 0; j < this.answerSheet[i].length; j++) {
                        if (!this.answerSheet[i][j].selected) {
                            count++
                        }
                    }
                }
                const h = this.$createElement;
                let confirmText = h('p', null, [h('span', null, '您当前还有'), h('strong', {style: 'fontWeight:400;color:#E84932;fontSize:24px'}, `${count}`), h('span', null, '道题未作答，是否提交该试卷')])
                let newDatas = [];
                if (count > 0) {
                    newDatas = [confirmText, '退出后记得再次进入该试卷继续答题'];
                } else {
                    newDatas = [`是否提交该试卷，`, '退出后可再次进入该试卷继续答题']
                }
                this.$confirm('交卷提示', {
                    title: '提示信息',
                    message: h('div', null, newDatas),
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                    this.affirmSubmit();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消交卷'
                    });
                });
            },
            // 确定交卷
            affirmSubmit() {
                let arr = ['single_data', 'multiple_data', 'judge_data', 'fillblank_data', 'answer_data'];
                let list = [];
                arr.forEach(item => {
                    if (this.paperInfo.theory_topic_data[item]) {
                        this.paperInfo.theory_topic_data[item].forEach(topicItem => {
                            let obj = {
                                id: topicItem.id,
                            };
                            if (item === 'single_data' || item === 'judge_data' || item === 'answer_data') {
                                if (topicItem.stu_answer[0] === '') {
                                    obj.stu_answer = []
                                } else {
                                    obj.stu_answer = topicItem.stu_answer
                                }
                                list.push(obj)
                            }
                            if (item === 'multiple_data') {
                                let multipleList = [];
                                topicItem.stu_answer.forEach(multipleItem => {
                                    if (multipleItem !== '') {
                                        multipleList.push(multipleItem)
                                    }
                                })
                                if (multipleList.length) {
                                    obj.stu_answer = multipleList.sort(function (a, b) {
                                        return a - b
                                    })
                                } else {
                                    obj.stu_answer = []
                                }
                                list.push(obj)
                            }
                            if (item === 'fillblank_data') {
                                let fillBlankList = [];
                                topicItem.stu_answer.forEach((fillBlankItem, fillBlankIndex) => {
                                    fillBlankList[fillBlankIndex] = fillBlankItem.name;
                                })
                                obj.stu_answer = fillBlankList;
                                list.push(obj)
                            }
                        })
                    }
                })
                let params = {
                    id: this.$route.query.id,
                    stu_answer: list
                }
                jding_save(params).then((res) => {
                    this.$message.success(res.msg)
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            // 展开收起
            packUpBtn() {
                if (this.hidePackUp) {
                    this.hidePackUp = false
                    this.packUpText = '展开'
                } else {
                    this.hidePackUp = true
                    this.packUpText = '收起'
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .detail-container {
        height: 100%;
        display: flex;

        .detail-container-left {
            display: flex;
            flex-direction: column;
            width: 1%;
            flex: 1;

            .paper-title {
                font-size: 24px;
                text-align: center;
                line-height: 1;
                padding: 4px 0 24px;
                border-bottom: 2px solid #E5E5E5;
                margin-bottom: 30px;
            }
        }

        .detail-container-right {
            width: 310px;
            display: flex;
            flex-direction: column;
            background: #F5F7F6;
            position: relative;
            padding-left: 20px;
            box-sizing: border-box;

            .top-shelter {
                padding-left: 20px;
                box-sizing: border-box;
                position: absolute;
                width: 100%;
                height: 20px;
                background: #F5F7F6;
                top: -20px;
                left: 0;
                display: flex;

                .top-shelter-color {
                    background: #fff;
                    flex: 1;
                    width: 1%;
                    height: 100%;
                }
            }

            .right-header {
                display: flex;
                justify-content: space-between;
                line-height: 40px;
                border-bottom: 1px solid #E5E5E5;
                padding: 0 5px;
                box-sizing: border-box;
                background: #fff;

                .left {
                    margin-left: 23px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -13px;
                        width: 3px;
                        height: 14px;
                        background: #E94319;
                        transform: translateY(-50%);
                    }
                }

                .packUp-btn {
                    color: #E94319;
                    cursor: pointer;

                    .iconfont {
                        transition: all .3s;
                    }

                    &.current {
                        .iconfont {
                            display: inline-block;
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .right-wrapper-box {
                height: 1%;
                flex: 1;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;

                    .el-scrollbar__view {
                        background: #fff;
                        padding-bottom: 30px;
                        box-sizing: border-box;
                    }
                }

                .packUp-wrapper {
                    padding-top: 10px;
                    box-sizing: border-box;
                }

                .right-content {
                    .right-item {
                        .item-one {
                            margin: 10px 0 0 18px;
                        }

                        &:first-of-type {
                            .item-one {
                                margin: 0px 0 0 18px;
                            }
                        }

                        .item-two {
                            margin-left: 10px;
                            margin-right: 18px;

                            .item {
                                display: inline-block;
                                width: calc(100% / 7 - 8px);
                                height: 24px;
                                margin-left: 8px;
                                margin-top: 10px;
                                border: 1px solid #D2D2D2;
                                border-radius: 4px;
                                box-sizing: border-box;
                                text-align: center;
                                line-height: 24px;

                                &.done {
                                    background: #E5E5E5;
                                }
                            }
                        }
                    }
                }

                .submit-btn-bj {
                    background: #fff;
                    display: flex;
                    flex-direction: column;
                }

                .submit-btn {
                    margin: 36px 18px 0;
                    height: 40px;
                    background: linear-gradient(138deg, #FF9A5A 0%, #FF5E03 100%);
                    border-radius: 4px;
                    font-size: 16px;
                    text-align: center;
                    line-height: 38px;
                    color: #fff;
                    cursor: pointer;
                    box-sizing: border-box;
                }

                .status-wrapper {
                    margin: 128px 0 0px;
                    display: flex;
                    justify-content: center;

                    .status-item {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .color {
                            width: 30px;
                            height: 24px;
                            border-radius: 4px;
                            border: 1px solid #D2D2D2;
                        }

                        .text {
                            margin-top: 5px;
                        }

                        &.done {
                            margin-right: 54px;

                            .color {
                                background: #E5E5E5;
                            }
                        }
                    }
                }
            }
        }
    }
</style>