<template>
    <div class="paper-detail" v-if="Object.keys(paperDetail).length > 0">
        <el-scrollbar class="exerciseDetail-main" :native="false">
            <div
                    class="headline-item"
                    :id="`questionType_${headlineKey}`"
                    v-for="(headlineItem, headlineKey, headlineIndex) in paperDetail"
                    :key="`paperDetail_${headlineIndex}`"
            >
                <div class="headlineType-title">
                    <span class="title">{{ (headlineIndex + 1) | formatChinese }}、{{ headlineKey | formatHeadLineType }}</span>
                    <span class="subtitle">(共{{ headlineItem.length}}题)</span>
                </div>
                <div
                        class="subject-item"
                        :id="headlineKey + '_' + questionIndex"
                        v-for="(questionItem, questionIndex) in headlineItem"
                        :key="`paperDetail_${questionIndex}`"
                >
                    <div class="question-title">
                        <span>{{ questionIndex + 1 }}.</span>
                        <span class="type">【{{ headlineKey | formatHeadLineType }}】</span>
                        <span class="title" v-html="questionItem.title"></span>
                        <span class="score">({{ questionItem.score }}分)</span>
                    </div>
                    <div class="answer-box">
                        <!--      S单选||判断      -->
                        <el-radio-group
                                v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'"
                                v-model="questionItem.stu_answer[0]"
                                class="question-answer">
                            <el-radio
                                    :label="answerIndex"
                                    class="answer-item"
                                    v-for="(answerItem, answerIndex) in questionItem.options">
                                <span>{{ (answerIndex + 1) | formatNumber }}、</span>{{ answerItem.name }}
                            </el-radio>
                        </el-radio-group>
                        <!--      E单选||判断      -->
                        <!--     S多选       -->
                        <el-checkbox-group
                                v-if="headlineKey === 'multiple_data'"
                                v-model="questionItem.stu_answer"
                                class="question-answer">
                            <el-checkbox
                                    :label="answerIndex"
                                    class="answer-item"
                                    v-for="(answerItem, answerIndex) in questionItem.options">
                                {{ (answerIndex + 1) | formatNumber }}、{{ answerItem.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                        <!--     E多选       -->
                        <!--     S填空       -->
                        <div class="question-answer flex" v-if="headlineKey === 'fillblank_data'">
                            <div class="answer-item" style="line-height: 40px">答案：</div>
                            <div class="answer-content">
                                <!--                                <template v-if="isSubmit === 0">-->
                                <div class="answer-item"
                                     v-for="(answerItem, answerIndex) in questionItem.stu_answer">
                                    {{ answerIndex + 1 }}、
                                    <el-input
                                            v-model.trim="answerItem.name"
                                            placeholder="请输入内容"
                                            style="width: 660px;"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                        <!--     E填空       -->
                        <!--     S问答       -->
                        <div class="question-answer flex" v-if="headlineKey === 'answer_data'">
                            <div class="answer-item" style="line-height: 40px">答案：</div>
                            <div class="answer-content">
                                <div class="answer-item">
                                    <el-input
                                            type="textarea"
                                            resize="none"
                                            placeholder="此处作答"
                                            :autosize="{ minRows: 4, maxRows: 4 }"
                                            v-model="questionItem.stu_answer[0]"
                                            style="max-height: 200px;width: 100%"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                        <!--     E问答       -->
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        name: "ExamTheoryPaper",
        props: {
            paperDetail: {
                type: Object,
                default: () => {
                },
            },
        },
        filters: {
            // 题型
            formatHeadLineType(type) {
                const types = {
                    single_data: "单选题",
                    multiple_data: "多选题",
                    judge_data: "判断题",
                    fillblank_data: "填空题",
                    answer_data: "问答题",
                };
                return types[type];
            },
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    "1": "A",
                    "2": "B",
                    "3": "C",
                    "4": "D",
                    "5": "E",
                    "6": "F",
                };
                return numbers[nums];
            },
            // 汉字一二三四五六
            formatChinese(nums) {
                const numbers = {
                    "1": "一",
                    "2": "二",
                    "3": "三",
                    "4": "四",
                    "5": "五",
                    "6": "六",
                    "7": "七",
                    "8": "八",
                };
                return numbers[nums];
            },
        },
        data() {
            return {
                subjectType: 1, //题型
                subjectTypeList: [],
            };
        },
        mounted() {
            this.getSubjectTypeList();
        },
        methods: {
            // 获取题型切换菜单
            getSubjectTypeList() {
                for (let key in this.paperDetail) {
                    let tmp = {};
                    if (key === "single_data") {
                        tmp.label = 1;
                        tmp.name = "single_data";
                    } else if (key === "multiple_data") {
                        tmp.label = 2;
                        tmp.name = "multiple_data";
                    } else if (key === "judge_data") {
                        tmp.label = 3;
                        tmp.name = "judge_data";
                    } else if (key === "fillblank_data") {
                        tmp.label = 4;
                        tmp.name = "fillblank_data";
                    } else if (key === "answer_data") {
                        tmp.label = 5;
                        tmp.name = "answer_data";
                    }
                    this.subjectTypeList.push(tmp);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }

    .paper-detail {
        display: flex;
        flex-direction: column;
        height: 1%;
        flex: 1;
        padding-top: 20px;

        .exerciseDetail-main {
            height: 1%;
            flex: 1;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .headline-item {
            margin-top: 40px;

            &:first-child {
                margin-top: 0;
            }

            .headlineType-title {
                padding-left: 40px;
                margin-bottom: 20px;

                .title {
                    font-size: 18px;
                    font-weight: bold;
                }

                .subtitle {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .subject-item {
            font-size: 16px;
            margin-bottom: 20px;

            .question-title {
                background: #f7f7f7;
                padding: 15px 40px;

                .type {
                    font-weight: 500;
                }

                .title {
                    ::v-deep p {
                        display: inline;
                    }

                    ::v-deep img {
                        max-width: 300px !important;
                        max-height: 300px !important;
                    }
                }

                .score {
                    font-weight: 500;
                }
            }

            .answer-box {
                .question-answer {
                    padding: 0 40px;

                    &.el-checkbox-group,
                    &.el-radio-group {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .answer-item {
                        margin-top: 20px;
                        font-weight: 400;
                        color: #333;

                        &.el-radio {
                            display: block;
                            width: 100%;
                            white-space: normal;
                            line-height: 1.5;
                            margin-right: 0;

                            ::v-deep .el-radio__label {
                                font-size: 16px;
                            }
                        }

                        ::v-deep .el-radio__input {
                            white-space: normal;

                            &.is-checked {
                                & + .el-radio__label {
                                    color: #333;
                                }

                                .el-radio__inner {
                                    border-color: #2dc079;
                                    /*background: #2DC079;*/
                                    background: transparent;

                                    &:after {
                                        width: 5px;
                                        height: 5px;
                                        background-color: #2dc079;
                                    }
                                }
                            }

                            .el-radio__inner {
                                &:hover {
                                    border-color: #2dc079;
                                }
                            }
                        }

                        &.el-checkbox {
                            white-space: normal;
                            line-height: 1.5;
                            display: block;
                            width: 100%;
                            margin-right: 0;

                            ::v-deep .el-checkbox__label {
                                font-size: 16px;
                            }
                        }

                        ::v-deep .el-checkbox__input {
                            white-space: normal;

                            .el-checkbox__label {
                                /*font-size: 16px;*/
                            }

                            &.is-checked {
                                & + .el-checkbox__label {
                                    color: #333;
                                }

                                .el-checkbox__inner {
                                    background-color: #2dc079;
                                    border-color: #2dc079;
                                }
                            }

                            &.is-indeterminate {
                                .el-checkbox__inner {
                                    background-color: #2dc079;
                                    border-color: #2dc079;
                                }
                            }

                            .el-checkbox__inner {
                                &:hover {
                                    border-color: #2dc079;
                                }
                            }
                        }
                    }

                    .answer-content {
                        width: 1%;
                        flex: 1;
                    }
                }
            }
        }
    }

    /*单选框组 样式覆盖*/
    .radioGroup-purple {
        margin: 0 40px 30px;

        ::v-deep .el-radio-button {
            margin-left: 16px;

            .el-radio-button__inner {
                color: #333;
                border: 1px solid #bfbfbf;
                border-radius: 4px;
                padding: 10px 0;
                width: 64px;
                font-weight: normal;

                &:hover {
                    color: #2dc079;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:first-child,
            &:last-child {
                .el-radio-button__inner {
                    border-radius: 0;
                }
            }

            .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                color: #fff;
                background-color: #2dc079;
                border-color: #2dc079;
                box-shadow: -1px 0 0 0 #2dc079;
                border-radius: 4px;
            }
        }
    }
</style>
